import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ApolloQueryResult } from '@apollo/client';
import { map, Observable, startWith } from 'rxjs';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';

import {
  GetDocumentSummaryQuery,
  GetDocumentSummaryQueryService,
} from '../../shared/document-summary.onelife.generated';

type SummaryData = {
  summary?: string;
  unavailable?: boolean;
};

@Component({
  selector: 'omg-document-summary',
  templateUrl: './document-summary.component.html',
  styleUrls: ['./document-summary.component.scss'],
})
export class DocumentSummaryComponent implements OnInit {
  documentSummarizationVisible = false;
  documentSummaryLevel = 'detailed';
  documentSummaryFeedbackVisible = false;
  summary$: Observable<GetDocumentSummaryQuery['document']['summary']>;
  loading$: Observable<boolean>;
  detailedSummary$: Observable<SummaryData>;
  shortSummary$: Observable<SummaryData>;

  @Input() documentId: number;
  feedbackForm: UntypedFormGroup;

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private documentSummaryService: GetDocumentSummaryQueryService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.feedbackForm = this.formBuilder.group({
      feedbackResponse: new UntypedFormControl(),
    });
  }

  ngOnInit() {
    this.documentSummarizationVisible = this.launchDarklyService.variation(
      FeatureFlagNames.chartDocumentSummarizationVisible,
      false,
    );
    this.documentSummaryLevel = this.launchDarklyService.variation(
      FeatureFlagNames.chartDocumentSummarizationLevel,
      'detailed',
    );
    this.documentSummaryFeedbackVisible = this.launchDarklyService.variation(
      FeatureFlagNames.chartDocumentSummarizationFeedback,
      false,
    );

    this.summary$ = this.documentSummaryService
      .fetch({
        documentId: this.documentId.toString(),
      })
      .pipe(map(summaryResult => summaryResult.data.document.summary));
    this.loading$ = this.summary$.pipe(
      startWith(undefined),
      map(summary => (summary === undefined ? true : false)),
    );
    this.detailedSummary$ = this.summary$.pipe(
      map(summary =>
        summary?.data['detailedSummary']
          ? { summary: summary.data['detailedSummary'] }
          : { unavailable: true },
      ),
    );
    this.shortSummary$ = this.summary$.pipe(
      map(summary =>
        summary?.data['shortSummary']
          ? { summary: summary.data['shortSummary'] }
          : { unavailable: true },
      ),
    );
  }

  get detailedSummaryVisible(): boolean {
    return ['detailed', 'both'].includes(this.documentSummaryLevel);
  }

  get shortSummaryVisible(): boolean {
    return ['short', 'both'].includes(this.documentSummaryLevel);
  }

  get bothSummaryVisible(): boolean {
    return this.documentSummaryLevel === 'both';
  }
}
